import React from "react"
import { Link } from "gatsby"

import Robots from '../assets/robots.jpg';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center">
            <img className=" margin-btm-md WI-header" src={Robots} alt="Illustrations of Labs members as robots" />
            <div className="align-left">
              <h1 className="header--medium margin-btm-xxs">Sunlight Foundation - Robot Heads</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>While at the Sunlight Foundation, I created robot profile pictures for each labs member.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Creative Director</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Illustrator</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-ali-illustrations">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link to="/ali-illustrations/">
                  <h3 className="header--medium">Illustrations of my Favorite Things</h3>
                </Link>
                <span className="description">Illustration</span>
              </div>
              <Link to="/ali-illustrations/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
